export default {
    bloglist: {
        en: [
            {
                title: 'Methods to use same WhatsApp Account on Multiple phones',
                poster: require('@/assets/blog-1.webp'),
                desc: "If you're trying to use the same WhatsApp account on two different phones, the official WhatsApp app doesn't allow this. However, if you're using GB WhatsApp, a modified version of WhatsApp, you can indeed use the same account on multiple devices at once.",
                name: 'en-blog1'
            },
            {
                title: 'How to Recover GB WhatsApp Data?',
                poster: require('@/assets/blog-2.webp'),
                desc: 'Many users prefer GB WhatsApp over the official app, primarily because of its enhanced features, especially for handling work-related or business conversations. This often means that the data stored on GB WhatsApp is more valuable and irreplaceable.',
                name: 'en-blog2'
            },
            {
                title: 'Exciting Update: GBWhatsApp Introduces Channels Feature',
                poster: require('@/assets/blog-cv-3.webp'),
                desc: "GBWhatsApp has introduced an exciting new feature - Channels, similar to the ones on Telegram. This allows you to share information and engage with your audience in a streamlined way.",
                name: 'en-blog3'
            },
            {
                title: 'Common Questions About GB WhatsApp',
                poster: require('@/assets/blog-cv-4.webp'),
                desc: 'GB WhatsApp, also known as "WhatsApp GB" or "Ogmods," is a modified version of the standard WhatsApp application. It offers enhanced features such as customizable themes, the ability to hide your online status, and even the option to enable airplane mode within the app.',
                name: 'en-blog4'
            },
            {
                title: "The 10 Apps to Spy WhatsApp Activity",
                poster: require('@/assets/blog-cv-5.webp'),
                desc: 'One of the most frequently asked questions online is how to spy someone else\'s WhatsApp conversations without needing access to their phone. Whether driven by curiosity or concern, people are eager to learn more about this. Today, we\'ll explore some tools designed for this purpose, while also discussing ethical considerations.',
                name: 'en-blog5'
            },
            {
                title: '10 Popular WhatsApp Mods for Android in 2024',
                poster: require('@/assets/blog-cv-6.webp'),
                desc: 'WhatsApp has become a big platform with over 2 billion active users worldwide, offering core messaging, calling, and media-sharing functions. However, some developers wanted to push these boundaries by creating modified versions, or "mods", which build on WhatsApp',
                name: 'en-blog6'
            },
            {
                title: 'Hot Discussion about If GBWhatsApp is Over',
                poster: require('@/assets/blog-cv-7.webp'),
                desc: 'GBWhatsApp, one of the most popular WhatsApp Mods, has long provided users with added features and customization options. However, recent issues have raised concerns about the future of GBWhatsApp, leading to the question: Is GBWhatsApp over?',
                name: 'en-blog7'
            }
        ],
        ar: [
            {
                title: 'طرق لاستخدام نفس حساب WhatsApp على هواتف متعددة',
                poster: require('@/assets/blog-1.webp'),
                desc: "إذا كنت تحاول استخدام نفس حساب WhatsApp على هاتفين مختلفين، فإن تطبيق WhatsApp الرسمي لا يسمح بذلك. ومع ذلك، إذا كنت تستخدم GB WhatsApp، وهو إصدار معدل من WhatsApp، يمكنك بالفعل استخدام نفس الحساب على أجهزة متعددة في نفس الوقت.",
                name: 'ar-blog1'
            },
            {
                title: 'كيف تستعيد بيانات GB WhatsApp؟',
                poster: require('@/assets/blog-2.webp'),
                desc: 'يفضل العديد من المستخدمين GB WhatsApp على التطبيق الرسمي، ويرجع ذلك أساسًا إلى ميزاته المحسّنة، خاصةً فيما يتعلق بالمحادثات المتعلقة بالعمل أو الأعمال. وغالبًا ما يعني ذلك أن البيانات المخزنة على GB WhatsApp أكثر قيمة وغير قابلة للاستبدال.',
                name: 'ar-blog2'
            },
            {
                title: 'تحديث مثير: يقدم GBWhatsApp ميزة القنوات',
                poster: require('@/assets/blog-cv-3.webp'),
                desc: "قدم GBWhatsApp ميزة جديدة مثيرة - القنوات، مشابهة لتلك الموجودة على Telegram. يسمح لك ذلك بمشاركة المعلومات والتفاعل مع جمهورك بطريقة مبسطة.",
                name: 'ar-blog3'
            },
            {
                title: 'أسئلة شائعة حول GB WhatsApp',
                poster: require('@/assets/blog-cv-4.webp'),
                desc: 'GB WhatsApp، المعروف أيضًا باسم "WhatsApp GB" أو "Ogmods"، هو إصدار معدل من تطبيق WhatsApp القياسي. يقدم ميزات محسنة مثل الثيمات القابلة للتخصيص، والقدرة على إخفاء حالة الاتصال الخاصة بك، وحتى خيار تمكين وضع الطيران داخل التطبيق.',
                name: 'ar-blog4'
            },
            {
                title: 'أفضل 10 تطبيقات للتجسس على نشاط WhatsApp',
                poster: require('@/assets/blog-cv-5.webp'),
                desc: 'واحدة من أكثر الأسئلة شيوعًا على الإنترنت هي كيفية التجسس على محادثات WhatsApp لشخص آخر دون الحاجة إلى الوصول إلى هاتفه. سواء بدافع الفضول أو القلق، يتطلع الناس إلى معرفة المزيد عن هذا. اليوم، سنستعرض بعض الأدوات المصممة لهذا الغرض، بينما نناقش أيضًا الاعتبارات الأخلاقية.',
                name: 'ar-blog5'
            },
            {
                title: '10 من أشهر مودات WhatsApp لأجهزة Android في 2024',
                poster: require('@/assets/blog-cv-6.webp'),
                desc: 'أصبح WhatsApp منصة كبيرة تضم أكثر من 2 مليار مستخدم نشط في جميع أنحاء العالم، وتقدم وظائف أساسية للرسائل والمكالمات ومشاركة الوسائط. ومع ذلك، أراد بعض المطورين دفع هذه الحدود من خلال إنشاء إصدارات معدلة، أو "مودات"، تتوسع على WhatsApp.',
                name: 'ar-blog6'
            },
            {
                title: "نقاش ساخن حول ما إذا كان GBWhatsApp قد انتهى",
                poster: require('@/assets/blog-cv-7.webp'),
                desc: "يعتبر GBWhatsApp أحد أشهر تعديلات واتساب، حيث يوفر للمستخدمين ميزات وخيارات تخصيص إضافية. ولكن، قضايا حديثة أثارت مخاوف حول مستقبل GBWhatsApp، مما يطرح السؤال: هل انتهى GBWhatsApp؟",
                name: "ar-blog7"
            }            
        ],
        es: [
            {
                title: 'Métodos para usar la misma cuenta de WhatsApp en varios teléfonos',
                poster: require('@/assets/blog-1.webp'),
                desc: "Si intentas usar la misma cuenta de WhatsApp en dos teléfonos diferentes, la aplicación oficial de WhatsApp no lo permite. Sin embargo, si usas GB WhatsApp, una versión modificada de WhatsApp, puedes usar la misma cuenta en múltiples dispositivos a la vez.",
                name: 'es-blog1'
            },
            {
                title: '¿Cómo recuperar los datos de GB WhatsApp?',
                poster: require('@/assets/blog-2.webp'),
                desc: 'Muchos usuarios prefieren GB WhatsApp sobre la aplicación oficial, principalmente debido a sus características mejoradas, especialmente para manejar conversaciones laborales o comerciales. Esto a menudo significa que los datos almacenados en GB WhatsApp son más valiosos e irreemplazables.',
                name: 'es-blog2'
            },
            {
                title: 'Actualización emocionante: GBWhatsApp presenta la función de canales',
                poster: require('@/assets/blog-cv-3.webp'),
                desc: "GBWhatsApp ha introducido una nueva función emocionante: canales, similar a los de Telegram. Esto te permite compartir información e interactuar con tu audiencia de manera más fluida.",
                name: 'es-blog3'
            },
            {
                title: 'Preguntas frecuentes sobre GB WhatsApp',
                poster: require('@/assets/blog-cv-4.webp'),
                desc: 'GB WhatsApp, también conocido como "WhatsApp GB" o "Ogmods", es una versión modificada de la aplicación estándar de WhatsApp. Ofrece características mejoradas como temas personalizables, la capacidad de ocultar tu estado en línea, e incluso la opción de habilitar el modo avión dentro de la aplicación.',
                name: 'es-blog4'
            },
            {
                title: 'Las 10 mejores aplicaciones para espiar la actividad de WhatsApp',
                poster: require('@/assets/blog-cv-5.webp'),
                desc: 'Una de las preguntas más frecuentes en línea es cómo espiar las conversaciones de WhatsApp de otra persona sin necesidad de acceso a su teléfono. Ya sea impulsado por curiosidad o preocupación, la gente está ansiosa por aprender más sobre esto. Hoy, exploraremos algunas herramientas diseñadas para este propósito, mientras también discutimos consideraciones éticas.',
                name: 'es-blog5'
            },
            {
                title: '10 mods de WhatsApp populares para Android en 2024',
                poster: require('@/assets/blog-cv-6.webp'),
                desc: 'WhatsApp se ha convertido en una gran plataforma con más de 2 mil millones de usuarios activos en todo el mundo, ofreciendo funciones básicas de mensajería, llamadas y compartición de medios. Sin embargo, algunos desarrolladores quisieron expandir estos límites creando versiones modificadas, o "mods", que se basan en WhatsApp.',
                name: 'es-blog6'
            },
            {
                title: "Gran Debate sobre el Fin de GBWhatsApp",
                poster: require('@/assets/blog-cv-7.webp'),
                desc: "GBWhatsApp, uno de los mods de WhatsApp más populares, ha proporcionado durante mucho tiempo funciones adicionales y opciones de personalización para los usuarios. Sin embargo, problemas recientes han generado dudas sobre el futuro de GBWhatsApp, planteando la pregunta: ¿Ha terminado GBWhatsApp?",
                name: "es-blog7"
            }            
        ],
        hi: [
            {
                title: 'एक ही WhatsApp खाते का उपयोग करने के तरीके कई फोन पर',
                poster: require('@/assets/blog-1.webp'),
                desc: "यदि आप दो अलग-अलग फोन पर एक ही WhatsApp खाता उपयोग करने की कोशिश कर रहे हैं, तो आधिकारिक WhatsApp ऐप इसकी अनुमति नहीं देता। हालाँकि, यदि आप GB WhatsApp का उपयोग कर रहे हैं, जो WhatsApp का एक संशोधित संस्करण है, तो आप वास्तव में कई उपकरणों पर एक ही खाते का उपयोग कर सकते हैं।",
                name: 'hi-blog1'
            },
            {
                title: 'GB WhatsApp डेटा को कैसे पुनः प्राप्त करें?',
                poster: require('@/assets/blog-2.webp'),
                desc: 'कई उपयोगकर्ता GB WhatsApp को आधिकारिक ऐप पर प्राथमिकता देते हैं, मुख्यतः इसकी उन्नत सुविधाओं के कारण, विशेष रूप से कार्य संबंधी या व्यावसायिक बातचीत को संभालने के लिए। इसका अर्थ यह है कि GB WhatsApp पर संग्रहीत डेटा अधिक मूल्यवान और अपरिवर्तनीय है।',
                name: 'hi-blog2'
            },
            {
                title: 'रोमांचक अपडेट: GBWhatsApp ने चैनल फीचर पेश किया',
                poster: require('@/assets/blog-cv-3.webp'),
                desc: "GBWhatsApp ने एक रोमांचक नई विशेषता - चैनल पेश किया है, जो टेलीग्राम पर मौजूद चैनलों के समान है। यह आपको जानकारी साझा करने और अपने दर्शकों के साथ सुगम तरीके से जुड़ने की अनुमति देता है।",
                name: 'hi-blog3'
            },
            {
                title: 'GB WhatsApp के बारे में सामान्य प्रश्न',
                poster: require('@/assets/blog-cv-4.webp'),
                desc: 'GB WhatsApp, जिसे "WhatsApp GB" या "Ogmods" के नाम से भी जाना जाता है, मानक WhatsApp एप्लिकेशन का एक संशोधित संस्करण है। यह अनुकूलन योग्य विषयों जैसी उन्नत सुविधाएँ, आपकी ऑनलाइन स्थिति छिपाने की क्षमता, और यहां तक कि ऐप के भीतर हवाई जहाज मोड सक्षम करने का विकल्प भी प्रदान करता है।',
                name: 'hi-blog4'
            },
            {
                title: 'WhatsApp गतिविधि पर जासूसी करने के लिए 10 ऐप',
                poster: require('@/assets/blog-cv-5.webp'),
                desc: 'ऑनलाइन सबसे अक्सर पूछे जाने वाले प्रश्नों में से एक है कि किसी और की WhatsApp बातचीत पर बिना उनके फोन तक पहुंच के कैसे जासूसी करें। चाहे जिज्ञासा या चिंता के कारण, लोग इस बारे में अधिक जानने के लिए उत्सुक हैं। आज, हम इस उद्देश्य के लिए डिज़ाइन किए गए कुछ उपकरणों की खोज करेंगे, जबकि नैतिक विचारों पर भी चर्चा करेंगे।',
                name: 'hi-blog5'
            },
            {
                title: '2024 में Android के लिए 10 लोकप्रिय WhatsApp Mods',
                poster: require('@/assets/blog-cv-6.webp'),
                desc: 'WhatsApp एक बड़ी प्लेटफ़ॉर्म बन गया है जिसमें दुनिया भर में 2 अरब से अधिक सक्रिय उपयोगकर्ता हैं, जो संदेश भेजने, कॉल करने और मीडिया साझा करने के मूल कार्य प्रदान करता है। हालाँकि, कुछ डेवलपर्स ने WhatsApp पर आधारित संशोधित संस्करण या "मोड" बनाने के लिए इन सीमाओं को आगे बढ़ाने की कोशिश की है।',
                name: 'hi-blog6'
            },
            {
                title: "GBWhatsApp के खत्म होने पर गर्म चर्चा",
                poster: require('@/assets/blog-cv-7.webp'),
                desc: "GBWhatsApp, सबसे लोकप्रिय WhatsApp मॉड्स में से एक है, जो लंबे समय से उपयोगकर्ताओं को अतिरिक्त सुविधाएँ और अनुकूलन विकल्प प्रदान कर रहा है। हालांकि, हाल की समस्याओं ने GBWhatsApp के भविष्य को लेकर चिंताएँ बढ़ाई हैं, जिससे यह सवाल उठता है: क्या GBWhatsApp खत्म हो गया है?",
                name: "hi-blog7"
            }            
        ],
        id: [
            {
                title: 'Metode untuk menggunakan akun WhatsApp yang sama di beberapa ponsel',
                poster: require('@/assets/blog-1.webp'),
                desc: "Jika Anda mencoba menggunakan akun WhatsApp yang sama di dua ponsel yang berbeda, aplikasi WhatsApp resmi tidak mengizinkannya. Namun, jika Anda menggunakan GB WhatsApp, versi modifikasi dari WhatsApp, Anda dapat menggunakan akun yang sama di beberapa perangkat sekaligus.",
                name: 'id-blog1'
            },
            {
                title: 'Bagaimana cara memulihkan data GB WhatsApp?',
                poster: require('@/assets/blog-2.webp'),
                desc: 'Banyak pengguna lebih memilih GB WhatsApp dibandingkan aplikasi resmi, terutama karena fitur-fitur unggulannya, terutama untuk menangani percakapan yang berhubungan dengan pekerjaan atau bisnis. Ini sering kali berarti bahwa data yang disimpan di GB WhatsApp lebih berharga dan tidak tergantikan.',
                name: 'id-blog2'
            },
            {
                title: 'Pembaruan Menarik: GBWhatsApp Memperkenalkan Fitur Saluran',
                poster: require('@/assets/blog-cv-3.webp'),
                desc: "GBWhatsApp telah memperkenalkan fitur baru yang menarik - Saluran, mirip dengan yang ada di Telegram. Ini memungkinkan Anda untuk berbagi informasi dan berinteraksi dengan audiens Anda dengan cara yang lebih terstruktur.",
                name: 'id-blog3'
            },
            {
                title: 'Pertanyaan Umum Tentang GB WhatsApp',
                poster: require('@/assets/blog-cv-4.webp'),
                desc: 'GB WhatsApp, yang juga dikenal sebagai "WhatsApp GB" atau "Ogmods," adalah versi modifikasi dari aplikasi WhatsApp standar. Ini menawarkan fitur-fitur unggulan seperti tema yang dapat disesuaikan, kemampuan untuk menyembunyikan status online Anda, dan bahkan opsi untuk mengaktifkan mode pesawat di dalam aplikasi.',
                name: 'id-blog4'
            },
            {
                title: '10 Aplikasi untuk Memata-matai Aktivitas WhatsApp',
                poster: require('@/assets/blog-cv-5.webp'),
                desc: 'Salah satu pertanyaan yang paling sering diajukan secara online adalah bagaimana cara memata-matai percakapan WhatsApp orang lain tanpa perlu mengakses ponsel mereka. Baik didorong oleh rasa ingin tahu atau kekhawatiran, orang ingin mengetahui lebih banyak tentang hal ini. Hari ini, kita akan menjelajahi beberapa alat yang dirancang untuk tujuan ini, sambil juga membahas pertimbangan etis.',
                name: 'id-blog5'
            },
            {
                title: '10 Mod WhatsApp Populer untuk Android di 2024',
                poster: require('@/assets/blog-cv-6.webp'),
                desc: 'WhatsApp telah menjadi platform besar dengan lebih dari 2 miliar pengguna aktif di seluruh dunia, menawarkan fungsi inti untuk mengirim pesan, melakukan panggilan, dan berbagi media. Namun, beberapa pengembang ingin memperluas batas ini dengan membuat versi modifikasi, atau "mod", yang dibangun di atas WhatsApp.',
                name: 'id-blog6'
            },
            {
                title: "Diskusi Panas tentang Apakah GBWhatsApp Sudah Berakhir",
                poster: require('@/assets/blog-cv-7.webp'),
                desc: "GBWhatsApp, salah satu mod WhatsApp yang paling populer, telah lama menyediakan fitur tambahan dan opsi kustomisasi untuk pengguna. Namun, masalah terbaru telah menimbulkan kekhawatiran tentang masa depan GBWhatsApp, yang menimbulkan pertanyaan: Apakah GBWhatsApp sudah berakhir?",
                name: "id-blog7"
            }            
        ],
        pt: [
            {
                title: 'Métodos para usar a mesma conta do WhatsApp em vários telefones',
                poster: require('@/assets/blog-1.webp'),
                desc: "Se você está tentando usar a mesma conta do WhatsApp em dois telefones diferentes, o aplicativo oficial do WhatsApp não permite isso. No entanto, se você estiver usando o GB WhatsApp, uma versão modificada do WhatsApp, você pode realmente usar a mesma conta em vários dispositivos ao mesmo tempo.",
                name: 'pt-blog1'
            },
            {
                title: 'Como recuperar dados do GB WhatsApp?',
                poster: require('@/assets/blog-2.webp'),
                desc: 'Muitos usuários preferem o GB WhatsApp em relação ao aplicativo oficial, principalmente por causa de seus recursos aprimorados, especialmente para lidar com conversas relacionadas ao trabalho ou negócios. Isso muitas vezes significa que os dados armazenados no GB WhatsApp são mais valiosos e irrecuperáveis.',
                name: 'pt-blog2'
            },
            {
                title: 'Atualização empolgante: GBWhatsApp apresenta o recurso de canais',
                poster: require('@/assets/blog-cv-3.webp'),
                desc: "O GBWhatsApp introduziu um novo recurso empolgante - Canais, semelhante aos do Telegram. Isso permite que você compartilhe informações e interaja com seu público de uma maneira mais organizada.",
                name: 'pt-blog3'
            },
            {
                title: 'Perguntas comuns sobre o GB WhatsApp',
                poster: require('@/assets/blog-cv-4.webp'),
                desc: 'O GB WhatsApp, também conhecido como "WhatsApp GB" ou "Ogmods", é uma versão modificada do aplicativo padrão do WhatsApp. Ele oferece recursos aprimorados, como temas personalizáveis, a capacidade de ocultar seu status online e até mesmo a opção de habilitar o modo avião dentro do aplicativo.',
                name: 'pt-blog4'
            },
            {
                title: 'Os 10 aplicativos para espionar a atividade do WhatsApp',
                poster: require('@/assets/blog-cv-5.webp'),
                desc: 'Uma das perguntas mais frequentes na internet é como espionar as conversas de WhatsApp de outra pessoa sem precisar acessar o telefone dela. Se motivados pela curiosidade ou preocupação, as pessoas estão ansiosas para saber mais sobre isso. Hoje, exploraremos algumas ferramentas projetadas para esse propósito, enquanto também discutimos considerações éticas.',
                name: 'pt-blog5'
            },
            {
                title: '10 Mods populares do WhatsApp para Android em 2024',
                poster: require('@/assets/blog-cv-6.webp'),
                desc: 'O WhatsApp se tornou uma grande plataforma com mais de 2 bilhões de usuários ativos em todo o mundo, oferecendo funções básicas de mensagens, chamadas e compartilhamento de mídia. No entanto, alguns desenvolvedores quiseram ultrapassar esses limites criando versões modificadas, ou "mods", que se baseiam no WhatsApp.',
                name: 'pt-blog6'
            },
            {
                title: "Grande Discussão sobre o Fim do GBWhatsApp",
                poster: require('@/assets/blog-cv-7.webp'),
                desc: "GBWhatsApp, um dos Mods de WhatsApp mais populares, há muito tempo oferece aos usuários recursos adicionais e opções de personalização. No entanto, problemas recentes levantaram preocupações sobre o futuro do GBWhatsApp, levando à pergunta: O GBWhatsApp chegou ao fim?",
                name: "pt-blog7"
            }            
        ]
    }
    
}